import React from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import HeroBannerImage from "../assets/images/banner.png";
const HeroBanner = () => {
    return (
        <Box
            sx={{
                mt: { lg: "212px", xs: "70px" },
                ml: { sm: "50px" },
            }}
            position="relative"
            p="20px"
        >
            <Typography color="#FF2625" fontWeight="600" fontSize="26px">
                Gym Rat
            </Typography>
            <Typography
                fontWeight={700}
                sx={{
                    fontSize: {
                        lg: "44px",
                        xs: "40px",
                    },
                }}
                mb="23px"
                mt="30px"
            >
                Visualize, Believe <br /> and Achieve
            </Typography>
            <Typography fontSize="22px" lineHeight="35px" mb={4}>
                Find the most effective workouts below
            </Typography>
            <Button
                variant="contained"
                color="error"
                href="#exercises"
                sx={{ backgroundColor: "#ff2525", padding: "10px" }}
            >
                Explore Exercises
            </Button>
            <Typography
                mt={3}
                fontWeight={600}
                color="#ff2625"
                sx={{
                    opacity: ".1",
                    display: { lg: "block", xs: "none" },
                }}
                fontSize="200px"
            >
                GYM RAT
            </Typography>
            <img
                src={HeroBannerImage}
                alt="banner"
                className="hero-banner-img"
            />
        </Box>
    );
};

export default HeroBanner;
